<template>
  <van-nav-bar
    title="储值卡"
    left-text="创建"
    right-text="储值记录"
    @click-left="onOpenForm(null)"
    @click-right="onGoDepositLog"
  />
  <van-list
    v-model:loading="loading"
    :finished="finished"
    finished-text="没有更多了"
    @load="getData"
  >
    <template v-for="item in list" :key="item">
      <div style="background:#fff;padding:15px;margin:15px 0 0 0;">
        <p style="margin:0 0 15px 0;">
          <small>充值金额 {{ item.depositAmount }}</small>
        </p>
        <p style="margin:0 0 15px 0;">
          <small>赠送金额 {{ item.depositGiftAmount }}</small>
        </p>
        <van-button @click="onOpenForm(item)">修改</van-button>&nbsp;
        <van-button :to="{ name:'ProfitSharingSettings', query: { productId: item.productId } }">设置分润</van-button>
      </div>
    </template>
  </van-list>
  <van-popup v-model:show="form.show" position="bottom">
    <van-form @submit="onSubmit">
      <van-field
        v-model="form.row.depositAmount"
        type="number"
        name="depositAmount"
        label="充值金额"
        placeholder=""
      />
      <van-field
        v-model="form.row.depositGiftAmount"
        type="number"
        name="depositGiftAmount"
        label="赠送金额"
        placeholder=""
      />
      <div style="margin: 16px;">
        <van-button round block type="primary" native-type="submit">提交</van-button>
        <template v-if="(typeof form.row.tagId !== 'undefined')">
          <van-button round block style="margin:15px 0 0 0;" @click="onDelete(form.row.tagId)">删除</van-button>
        </template>
      </div>
    </van-form>
  </van-popup>
</template>

<script>
import { toRefs, reactive, onMounted, inject } from 'vue'
import { Toast, Dialog } from 'vant'

export default {
  setup () {
    const post = inject('post')
    const Cookies = inject('Cookies')
    const useRouter = inject('useRouter')
    const state = reactive({
      page: 1,
      finished: false,
      loading: false,
      list: [],
      title: '',
      form: {
        show: false,
        row: {}
      },
      row: {
      },
      storeId: Cookies.get('storeId')
    })
    const onGoDepositLog = () => {
      useRouter.push({
        name: 'DepositLog'
      })
    }
    const onOpenForm = (item) => {
      state.form.show = true
      if (item !== null) {
        // state.form.row = item
        state.form.row = Object.assign({}, item)
      } else {
        state.form.row = {}
      }
    }
    const reset = () => {
      state.list = []
      state.page = 1
      state.finished = false
      state.loading = false
    }
    const onSubmit = (values) => {
      console.log(values)
      if (typeof state.form.row.productId === 'undefined') {
        post('/product.createDepositProduct', {
          ...values,
          storeId: state.storeId
        }).then(res => {
          if (res.code === 0) {
            Toast.success()
            reset()
            state.form.show = false
          } else {
            Toast(res.msg)
          }
        })
      } else {
        post('/product.updateDepositProduct', {
          ...values,
          productId: state.form.row.productId
        }).then(res => {
          if (res.code === 0) {
            Toast.success()
            reset()
            state.form.show = false
          } else {
            Toast(res.msg)
          }
        })
      }
    }
    const onDelete = (id) => {
      Dialog.confirm({
        title: '提示',
        message: '操作确认'
      }).then(() => {
        console.log(id)
        post('/product.delete', {
          tagIds: id.toString().split(',')
        }).then(res => {
          if (res.code === 0) {
            Toast.success()
            reset()
            state.form.show = false
          } else {
            Toast(res.msg)
          }
        })
      }).catch(() => {
      })
    }
    const getData = () => {
      post('/product.list', {
        pageNum: state.page,
        storeId: state.storeId,
        bizScope: 'DEPOSIT'
      }).then(res => {
        state.list = [...state.list, ...res.data.content]
        state.loading = false
        state.finished = res.data.last
        state.page++
        state.title = '总共 ' + res.data.totalElements + ' 条记录'
      })
    }
    const init = () => {
    }
    onMounted(() => {
      init()
    })
    return {
      ...toRefs(state),
      getData,
      onOpenForm,
      onSubmit,
      onDelete,
      onGoDepositLog
    }
  }
}
</script>

<style scoped>
body {
  background:#f9f9f9;
}
</style>
